.roadmap-wrapper{
    background-color: #111111;
    margin-top: 20px;
    padding-bottom: 80px;
}
.rm-text1{
    font-size: 16px;
    color: white;
}
.rm-text2{
    font-size: 20px;
    color: white;
}
.rm-text3{
    font-size: 16px;
    color: #D3B55B;
}
.rm-text4{
    font-size: 14px;
    color: #A4A4A4;
}
.rm-text5{
    font-size: 20px;
    color: #D3B55B;
}
.drop-div{
    margin-top: 50px;
    border: 1px solid white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    line-height: 1;
}
.rm-sub-div{
    margin-top: 30px;
    border: 1px solid #D3B55B;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
}

.rm-div2{
    padding-top: 70px;
}
.rm-div3{

}
