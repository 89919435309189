body{
    background-color: black;
    font-family: "Montserrat", sans-serif;

}
.button1{
    padding:10px 25px 10px 25px;
    border-radius: 10px;
    border: 1px solid #D3B55B;
    background-color:inherit;
    color: white;
}
.button1:hover{
    padding:10px 25px 10px 25px;
    border-radius: 10px;
    border: 1px solid #D3B55B;
    background-color:rgb(22, 21, 21);
    color: white;
}



.btn-icon{
    width: 17pxs;
    margin-right: 25px ;
}
.subheading{
    font-size: 30px;
    color:#D3B55B;
    font-family: "Orbitron", sans-serif !important;
    font-weight: 600;
}