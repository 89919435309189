.gallery-wrapper{
    background-color: black;
}
.gallery-text1{
    color: white;
    font-size: 14px;
}
.gallery-text2{
    color: white;
    font-size: 17px;

}
.left-bracket{
    height: 60px;
    margin-right: 10px;
}
.right-bracket{
    height: 60px;
    margin-left: 10px;
}

.gallery-title-text{
    padding-left: 15%;
    padding-right: 15%;
}

/* Flip cards */
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    /* border: 2px solid green; */
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    /* width: 100%;
    height: 100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: black;
    color: black;
    width: 250px;
    height: 330px;
    /* border: 2px solid red; */
  }
  
  .flip-card-back {
    background-color: black;
    color: white;
    transform: rotateY(180deg);
    border: 4px solid #D3B55B;
    border-radius: 10px;
    width: 250px;
    height: 330px;
    padding: 20px;
    margin-left: 10px;
  }
  .nft-image{
      width: 250px;
      height: 330px;
  }