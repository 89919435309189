.aboutyou-wrapper{
    background-color: black;
}
.aboutyou-text1{
    color: #D3B55B;
    font-size: 18px;
    font-family: "Orbitron", sans-serif;
}
.aboutyou-text2{
    color: white;
    font-size: 16px;
}
